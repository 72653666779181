"use client";

import React, { useContext, useState } from "react";
import styled from "styled-components";

import { usePageView } from "../../hooks/useGtag";
import { Header } from "./Header";
import { PointList } from "./PointList";
import { SlimContent, WideContent } from "./shared";
import { Top } from "./Top";
import { CTABlock } from "./CTABlock";
import { SmallPointList } from "./SmallPointList";
import { SceneList } from "./SceneList";
import { PlanProceedings } from "./PlanProceedings";
import { ContactForm } from "./ContactForm";
import { CaseSection } from "./CaseSection";
import { Footer } from "./Footer";
import { UserContext } from "../../contexts/UserContext";
import { RedirectLogin } from "../RedirectLogin";
import type { Apps } from "../../types/services";
import type { NTString } from "@rimo/i18n/types";
import { useSearchParams } from "next/navigation";

export type RimoVoiceGenericLPProps = {
  target: "proceedings" | "training" | "writing" | "businessAnalysis" | "userResearch" | "transcription";
};

export const RimoVoiceGenericLP: React.FC<RimoVoiceGenericLPProps> = (props) => {
  usePageView();
  const defaultPersonal = props.target === "writing";
  const [isPersonal, setIsPersonal] = useState(defaultPersonal);
  const searchParams = useSearchParams();
  const queryValue = searchParams?.get("displayCompanyDetailPlans") ?? "false";
  return (
    <Container>
      <Header target={props.target satisfies NTString} />
      <Top target={props.target} />
      <SlimContent>
        <PointList target={props.target} />
      </SlimContent>
      <SlimContent>
        <SmallPointList target={props.target} />
      </SlimContent>
      <WideContent>
        <CTABlock target={props.target} />
      </WideContent>
      <SlimContent>
        <SceneList target={props.target} />
      </SlimContent>
      <SlimContent>
        <PlanProceedings
          isPersonal={isPersonal}
          setIsPersonal={setIsPersonal}
          displayCompanyDetailPlans={queryValue === "true"}
        />
      </SlimContent>
      {props.target === "proceedings" || (props.target === "transcription" && <CaseSection />)}
      {!isPersonal && <ContactForm />}
      <Footer />
    </Container>
  );
};

// rimo.appへのアクセス時にはmiddlewareにて各LPへのリダイレクトを行う
export const RimoVoiceGenericLPForRoot: React.FC<RimoVoiceGenericLPProps> = (props) => {
  const {
    userState: { user, isLoaded },
  } = useContext(UserContext);
  if (isLoaded && user) {
    return <RedirectLogin apps={"voice" as Apps} />;
  }
  if (!isLoaded) return null;
  return <RimoVoiceGenericLP {...props} />;
};

const Container = styled.div`
  color: #222222;
`;
