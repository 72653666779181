import { Trans } from "react-i18next/TransWithoutContext";
import { useClientTranslation } from "@rimo/frontend/hooks/i18n/useClientTranslation";
import { PrimaryButton as PrimaryButtonBase } from "@rimo/frontend/components/landing/voice/Button";
import { Check } from "@styled-icons/feather/Check";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import styled, { css } from "styled-components";
import { Chevron as ChevronBase, Lead, MOBILE_BREAKPOINT, MobileBr } from "./shared";
import { Header2 } from "@rimo/ui-old";
import { Users, Building2 } from "lucide-react";

const businessPlanAskTitle = "ビジネス";
const PLAN_LIST_DATA = [
  {
    key: "trial",
    title: "トライアル",
    titleColor: "color: #FFAF00;",
    price: 3,
    scene: "低価格でお試ししたい方に",
    hoursPerMonth: "10",
    over: 30,
    points: ["自動話者分離"],
  },
  {
    key: "light",
    title: "ライト",
    titleColor: "color: #FF8000;",
    price: 6,
    scene: "1日1時間程度利用のチームに",
    hoursPerMonth: "20",
    over: 30,
    points: ["自動話者分離", "動画アップロード", "単語登録による学習"],
  },
  {
    key: "basic",
    title: "ベーシック",
    titleColor:
      "background: linear-gradient(269.96deg, #FFAF00 31.94%, #F14C00 89.64%);-webkit-background-clip: text;-webkit-text-fill-color: transparent;",
    price: 12,
    scene: "小規模チームでご利用の方",
    hoursPerMonth: "45",
    over: 27,
    points: ["自動話者分離", "動画アップロード", "単語登録による学習"],
  },
  {
    key: "business",
    title: businessPlanAskTitle,
    titleColor: "color: #F14C00;",
    price: "ASK",
    scene: "大規模組織でご利用の方",
    hoursPerMonth: "100",
    over: 25,
    points: ["自動話者分離", "動画アップロード", "単語登録による学習"],
  },
];

export const PlanProceedings: React.FC<{
  isPersonal: boolean;
  setIsPersonal: React.Dispatch<React.SetStateAction<boolean>>;
  displayCompanyDetailPlans: boolean;
}> = (props) => {
  const { customT } = useClientTranslation();

  const [selectedPlanKey, setSelectedPlanKey] = useState<string>("basic");

  const planOnclick = (key: string) => {
    setSelectedPlanKey(key);
  };

  return (
    <Content>
      <Lead>
        {customT("ニーズに応じた")}

        <MobileBr />
        {customT("プランと価格")}
      </Lead>
      <TabList>
        <Tab role="button" as="button" onClick={() => props.setIsPersonal(true)} $selected={props.isPersonal}>
          {customT("個人")}
        </Tab>
        <Tab role="button" as="button" onClick={() => props.setIsPersonal(false)} $selected={!props.isPersonal}>
          {customT("法人")}
        </Tab>
      </TabList>
      {props.isPersonal ? (
        <PersonalPlanWrapper>
          <PricingSubHead>{customT("1名様でご利用いただくための従量制プラン")}</PricingSubHead>
          <PricingPersonalBox>
            <PricingPersonalBoxSection>
              <PricingBoxPlanSection>
                <PricingBoxPlanSectionHeader>{customT("音声")}</PricingBoxPlanSectionHeader>
                <PricingBoxPlanSectionCost>22</PricingBoxPlanSectionCost>
                <PricingBoxPlanSectionUnit>{customT("円/30秒")}</PricingBoxPlanSectionUnit>
              </PricingBoxPlanSection>
              <PricingPersonalBoxCostSeparator />
              <PricingBoxPlanSection>
                <PricingBoxPlanSectionHeader>{customT("動画")}</PricingBoxPlanSectionHeader>
                <PricingBoxPlanSectionCost>33</PricingBoxPlanSectionCost>
                <PricingBoxPlanSectionUnit>{customT("円/30秒")}</PricingBoxPlanSectionUnit>
              </PricingBoxPlanSection>
            </PricingPersonalBoxSection>
            <PlanFeatureList>
              <PlanFeatureItem key={"personal"}>
                <PlanFeatureIcon></PlanFeatureIcon>
                <PlanFeatureText>
                  <PlanFeatureBold>{customT("音声60分")}</PlanFeatureBold>
                  {customT("の無料トライアル付き")}
                </PlanFeatureText>
              </PlanFeatureItem>
            </PlanFeatureList>
            <PlanPrimaryButtonWrapper>
              <PlanPrimaryButton $size="standard" link={`/about`}>
                {customT("いますぐ無料で始める")}
              </PlanPrimaryButton>
            </PlanPrimaryButtonWrapper>
          </PricingPersonalBox>
          <PricingFooterText>{customT("支払方法はクレジットカード払いのみになります。")}</PricingFooterText>
        </PersonalPlanWrapper>
      ) : !props.displayCompanyDetailPlans ? (
        <BusinessSinglePlan />
      ) : (
        <>
          <PlanList>
            {PLAN_LIST_DATA.map((i) => {
              const selected = i.key === selectedPlanKey;
              return (
                <PlanItem key={customT(i.key)} $selected={selected} onClick={() => planOnclick(i.key)}>
                  <MobileFlexWrapper>
                    <div>
                      <Title $textColor={i.titleColor}>{customT(i.title)}</Title>
                      <Price>
                        {i.price === "ASK" ? (
                          <span>ASK</span>
                        ) : (
                          <>
                            <Trans
                              i18nKey="special >> <span>{{iprice}}</span>万円"
                              components={{ span: <span /> }}
                              values={{ iprice: i.price }}
                            />

                            {customT("special >> /月")}
                          </>
                        )}
                      </Price>
                      <Scene>{customT(i.scene)}</Scene>
                    </div>
                    <Chevron $isOpen={selected} />
                  </MobileFlexWrapper>
                  <HiddenInMobile $selected={selected}>
                    <Divider $size="standard" />
                    <TimeStorage>
                      <Trans
                        i18nKey="<span>{{ihoursPerMonth}}</span>時間{{text}}/月の記録時間"
                        components={{ span: <span /> }}
                        values={{ ihoursPerMonth: i.hoursPerMonth, text: i.key === "business" ? "～" : "" }}
                      />
                    </TimeStorage>
                    <Over>
                      <Trans
                        i18nKey="超過分は30秒毎に<span>{{iover}}円</span>"
                        components={{ span: <span /> }}
                        values={{ iover: i.over }}
                      />
                    </Over>
                    <PointList>
                      {i.points.map((p) => (
                        <Point key={p}>{customT(p)}</Point>
                      ))}
                    </PointList>
                    {selected && (
                      <PrimaryButton $size="standard" query={`planName=${i.title}`} hash={`contact-form`}>
                        {customT("プランを選択")}
                      </PrimaryButton>
                    )}
                  </HiddenInMobile>
                </PlanItem>
              );
            })}
          </PlanList>
          <AppendixBlock>
            <AdditionalCostWrapper>
              <PlusIcon />
              <AdditionalCost>
                <AdditionalCostLabel>{customT("初期費用(アカウント発行手数料)")}</AdditionalCostLabel>
                <AdditionalCostContent>{customT("月額料金1ヶ月分")}</AdditionalCostContent>
              </AdditionalCost>
            </AdditionalCostWrapper>
            <AppendixDivider />
            <AppendixText>
              <Trans i18nKey="料金は税別です。支払い方法は請求書及びクレジットカードがご利用できます。 <br />ライト以上のプランでは4時間/1万円(単価約30秒20円)の単位で事前に時間追加できます。" />
            </AppendixText>
          </AppendixBlock>
        </>
      )}
    </Content>
  );
};

const Content = styled.div`
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    padding: 72px 0 80px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    padding-top: 48px;
  }
`;

const PlanList = styled.ul`
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    display: flex;
    gap: 16px;
    margin-top: 48px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    margin-top: 24px;
  }
`;
const PlanItem = styled.li<{
  $selected: boolean;
}>`
  border-radius: 16px;
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    padding: 24px 16px 34px;
    cursor: pointer;
    width: 100%;
    box-shadow:
      0px 2px 6px rgba(0, 0, 0, 0.1),
      0px 0px 0px 1px rgba(0, 0, 0, 0.02);
    ${(p) =>
      p.$selected &&
      `
    background-image: linear-gradient(269.96deg, rgba(255, 175, 0, 0.16) 31.94%, rgba(241, 76, 0, 0.16) 89.64%), url('/assets/about_genelic/bg-plan-tob-pc.png');
  `}
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    padding: 12px 16px;
    box-shadow:
      0px 8px 16px rgba(0, 0, 0, 0.1),
      0px 0px 0px rgba(0, 0, 0, 0.02);
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
    ${(p) =>
      p.$selected &&
      `
    background-image: linear-gradient(269.96deg, rgba(255, 175, 0, 0.16) 31.94%, rgba(241, 76, 0, 0.16) 89.64%), url('/assets/about_genelic/bg-plan-mobile.png');
  `}
  }
`;
const Title = styled.p<{
  $textColor: string;
}>`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    color: ${(p) => p.theme.vars.palette.primary.main};
  }
  && {
    ${(p) => p.$textColor}
  }
`;
const Price = styled.p<{
  $disableMarginTop?: boolean | undefined;
}>`
  font-weight: 700;
  color: rgba(0, 0, 0, 0.56);
  span {
    margin-right: 2px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.84);
  }
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    margin-top: ${(p) => (p.$disableMarginTop ? "0" : "16px")};
    font-size: 20px;
    line-height: 20px;
    span {
      font-size: 36px;
      line-height: 44px;
    }
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    margin-top: ${(p) => (p.$disableMarginTop ? "0" : "4px")};
    font-size: 14px;
    line-height: 20px;
    span {
      font-size: 28px;
      line-height: 36px;
    }
  }
`;
const Chevron = styled(ChevronBase)`
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    display: none;
  }
`;
const MobileFlexWrapper = styled.div`
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
const Scene = styled.p`
  color: #666666;
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    margin-top: 4px;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 8px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    font-size: 15px;
    line-height: 20px;
  }
`;
const HiddenInMobile = styled.div<{
  $selected: boolean;
}>`
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    ${(p) => !p.$selected && `display: none;`}
  }
`;
const Divider = styled.hr<{
  $size: "standard" | "ask";
}>`
  border: 1px solid rgba(0, 0, 0, 0.06);
  width: 100%;
  height: 0;
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    margin: ${(p) => (p.$size === "standard" ? "24px 0" : "16px 0")};
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    margin: 12px 0;
  }
`;
const TimeStorage = styled.div`
  color: rgba(0, 0, 0, 0.84);
  margin-top: 24px;
  margin-bottom: 8px;
  line-height: 20px;
  span {
    font-weight: 700;
    color: rgba(0, 0, 0, 0.84);
    margin-right: 2px;
    font-size: 18px;
  }
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    font-size: 14px;
    span {
      letter-spacing: 0.18px;
    }
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    font-size: 14px;
  }
`;
const Over = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #666666;
  span {
    color: rgba(0, 0, 0, 0.84);
    font-weight: 600;
  }
`;
const PointList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4px 4px;
  padding: 0;
  margin: 1rem 0 1.5rem 0;
  list-style: none;

  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    grid-template-columns: 1fr;
  }
`;
const Point = styled.li<{
  $marker?: boolean | undefined;
}>`
  font-size: 15px;
  color: #525252;
  ${(p) =>
    p.$marker &&
    css`
      list-style: disc;
      list-style-position: inside;
    `}
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    line-height: 22px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    line-height: 20px;
  }
`;
const PrimaryButton = styled(PrimaryButtonBase)<{
  $size: "standard" | "ask";
}>`
  padding: ${(p) => (p.$size === "standard" ? "6px 0" : "8px 0")};
  margin-top: ${(p) => (p.$size === "standard" ? "22px" : "8px")};
  width: 100%;
`;
const AppendixBlock = styled.div`
  margin-top: 32px;
  padding: 24px 0;
`;
const AdditionalCostWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;
const PlusIcon = styled(AddIcon)`
  width: 24px;
  height: 24px;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 16px;
`;
const AppendixDivider = styled.div`
  width: 100%;
  border: solid 1px rgba(0, 0, 0, 0.06);
  height: 0;
  margin: 16px 0 24px;
`;
const AdditionalCost = styled.div`
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.18px;
  width: 100%;
`;
const AdditionalCostLabel = styled.p``;
const AdditionalCostContent = styled.p``;
const AppendixText = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.56);
`;
const TOP_MOBILE_BREAKPOINT = 980;
const BOX_SIZE = 480;
const mobileWidth = css`
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
`;
const PricingSubHead = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  color: #000000;

  @media (max-width: ${`${TOP_MOBILE_BREAKPOINT}px`}) {
    ${mobileWidth}
  }
  margin-bottom: 48px;
`;

const PricingPersonalBox = styled.div`
  width: ${BOX_SIZE}px;
  height: 207px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-shadow:
    0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 0px 0px 1px rgba(0, 0, 0, 0.03);
  border-radius: 16px;
  margin: 0 auto 24px;
  display: inline-block;
  @media (max-width: ${BOX_SIZE}px) {
    width: 100%;
  }
`;

const PricingPersonalBoxSection = styled.div`
  display: flex;
`;
const PricingBoxPlanSection = styled.div`
  margin: 24px 24px 16px;
  width: 192px;
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const PricingPersonalBoxCostSeparator = styled.div`
  width: 1px;
  height: 48px;
  background: rgba(0, 0, 0, 0.06);
  margin-top: 29px;
`;
const PricingBoxPlanSectionHeader = styled.div`
  //   width: 32px;
  height: 24px;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
`;
const PricingBoxPlanSectionCost = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  /* identical to box height */

  display: inline-block;
  align-items: center;

  color: rgba(0, 0, 0, 0.84);
  margin-right: 4px;
`;
const PricingBoxPlanSectionUnit = styled.div`
  height: 21px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  display: inline-block;

  color: #666666;
`;

const PricingFooterText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  align-items: center;
  text-align: center;
  padding: 0 24px;
  box-sizing: border-box;
  width: 100%;

  color: #222222;
`;
const PlanFeatureList = styled.ul<{
  $minHeight?: number;
}>`
  width: 100%;
  padding-left: 24px;
  box-sizing: border-box;
  min-height: ${(props) => (props.$minHeight ? `${props.$minHeight}px` : "auto")};
`;
const PlanFeatureIcon = styled(Check)`
  width: 16px;
  color: ${(p) => p.theme.vars.palette.primary.main};
  margin-top: 4px;
  margin-right: 12px;
  stroke-width: 3;
`;
const PlanFeatureText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #666666;
  margin-bottom: 6px;
`;
const PlanFeatureBold = styled.span`
  color: #222222;
  font-weight: 700;
`;
const PlanPrimaryButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const PlanPrimaryButton = styled(PrimaryButton)`
  margin: 32px auto 0;
  padding: 10px 20px;
  width: fit-content;
`;
const PlanFeatureItem = styled.li`
  display: flex;
  align-items: flex-start;
  height: 21px;
  margin-bottom: 4px;
  &:last-child {
    margin-bottom: 0 !important;
  }
`;

const TabList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px auto 18px;
`;
const Tab = styled(Header2)<{
  $selected: boolean;
}>`
  border: none;
  background: none;
  outline: none;
  padding: 10px 40px;
  cursor: pointer;
  ${(p) =>
    p.$selected &&
    css`
      border-bottom: solid 3px ${p.theme.vars.palette.primary.light};
    `}
`;
const PersonalPlanWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const SmallText = styled.span`
  font-size: 0.875rem;
  font-weight: bold;
  color: ${(props) => props.theme.vars.palette.text.primary};
`;

const BusinessSinglePlan: React.FC = () => {
  const { customT } = useClientTranslation();

  return (
    <BusinessSinglePlanContainer $selected={false}>
      <BusinessSinglePlanContent>
        <div>
          <Price $disableMarginTop>
            <span>ASK</span>
          </Price>
          <Scene>{customT("使い方に合わせてご提案")}</Scene>

          {/* Small Scale Plan */}
          <PlanSection>
            <IconBackground color="#3B82F6">
              <IconWrapper color="#3B82F6">
                <Users size={24} strokeWidth={1.5} />
              </IconWrapper>
            </IconBackground>
            <PlanContent>
              <PlanTitle>
                <Trans i18nKey="小模導入向け <SmallText>定額制</SmallText>" components={{ SmallText: <SmallText /> }} />
              </PlanTitle>
              <PlanFeatures>
                <PlanFeature>{customT("1〜3部署での利用を想定")}</PlanFeature>
                <PlanFeature>{customT("利用時間に応じた柔軟なプラン")}</PlanFeature>
              </PlanFeatures>
            </PlanContent>
          </PlanSection>

          {/* Large Scale Plan */}
          <PlanSection>
            <IconBackground color="#F97316">
              <IconWrapper color="#F97316">
                <Building2 size={24} strokeWidth={1.5} />
              </IconWrapper>
            </IconBackground>
            <PlanContent>
              <PlanTitle>
                <Trans
                  i18nKey="大規模導入向け <SmallText>運用保守費用 + AI原価</SmallText>"
                  components={{ SmallText: <SmallText /> }}
                />
              </PlanTitle>
              <PlanFeatures>
                <PlanFeature>{customT("全社での導入利用を想定")}</PlanFeature>
                <PlanFeature>{customT("カスタマイズ対応可能")}</PlanFeature>
              </PlanFeatures>
            </PlanContent>
          </PlanSection>
        </div>
        <div>
          <Divider $size="ask" />
          <TimeStorage>{customT("法人プランで利用可能な機能")}</TimeStorage>
          <PointList>
            <Point $marker>{customT("自動話者分離")}</Point>
            <Point $marker>{customT("ユーザー数無制限")}</Point>
            <Point $marker>{customT("動画の録画容量無制限")}</Point>
            <Point $marker>{customT("単語登録による学習")}</Point>
          </PointList>
          <PrimaryButton $size="ask" query={`planName=${businessPlanAskTitle}`} hash={`contact-form`}>
            {customT("資料請求 & お問い合わせ")}
          </PrimaryButton>
        </div>
      </BusinessSinglePlanContent>
      <BusinessSinglePlanPaperImage>
        <img src="/assets/about_voice/sales_material_paper.png" alt="Sales Material" />
      </BusinessSinglePlanPaperImage>
    </BusinessSinglePlanContainer>
  );
};
const BusinessSinglePlanContent = styled.div`
  padding: 32px 40px 32px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    width: 60%;
    min-width: 440px;
  }

  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    width: 100%;
  }
`;
const BusinessSinglePlanPaperImage = styled.div`
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    width: 40%;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    width: 100%;
    padding: 40px 20px;
  }

  img {
    max-width: 400px;
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`;
const BusinessSinglePlanContainer = styled.div<{
  $selected: boolean;
}>`
  list-style: none;
  padding: 0;
  cursor: initial;
  background: #fff;
  border-radius: 24px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);

  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    width: 100%;
    min-height: 280px;
    height: auto;
    display: flex;
    align-items: stretch;
    margin: 0 auto;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    display: flex;
    flex-direction: column-reverse;
  }
`;
const PlanSection = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-top: 1rem;
`;

const PlanContent = styled.div`
  flex: 1;
`;

const PlanTitle = styled.h3`
  font-size: 1.125rem;
  color: ${(props) => props.theme.vars.palette.text.primary};
`;

const PlanFeatures = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  color: #4b5563;
`;

const PlanFeature = styled.li`
  padding-left: 1rem;
  position: relative;
  line-height: 1.5;
  display: flex;
  flex-wrap: wrap;

  &:before {
    content: "•";
    position: absolute;
    left: 0;
  }
`;
const IconBackground = styled.div<{
  color: string;
}>`
  width: 48px;
  height: 48px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.color === "#3B82F6" ? "rgba(59, 130, 246, 0.1)" : "rgba(249, 115, 22, 0.1)")};
`;
const IconWrapper = styled.div<{
  color: string;
}>`
  color: ${(props) => props.color};
  width: 24px;
  height: 24px;
`;
